import React from "react"
import styled from "@emotion/styled"
import { motion } from "framer-motion"
import TravelLayout from "../../layouts/travelLayout"
import SEO from "../../components/seo"
import Map from "../../components/map"
import { ParentSize } from "@visx/responsive"

const Container = styled(motion.div)`
  // width: 95%;
  height: 650px;

  @media (max-width: 700px) {
    height: 500px;
  }
`

const Caption = styled.div`
  text-align: center;
  font-size: 0.8rem;
  font-style: italic;
  margin-top: 20px;
`
const Travel = ({ location }) => {
  return (
    <TravelLayout>
      <SEO
        description="Countries I've visited."
        title="Travel"
        pathname={location.pathname}
      />
      <Container
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <ParentSize>
          {({ width, height }) => <Map width={width} height={height} />}
        </ParentSize>
        <Caption>Countries I&apos;ve Visited</Caption>
      </Container>
    </TravelLayout>
  )
}

export default Travel
