import React, { useMemo } from "react"
import { localPoint } from "@visx/event"
import { NaturalEarth } from "@visx/geo"
import { useTooltip, Tooltip, defaultStyles } from "@visx/tooltip"
import * as topojson from "topojson-client"
import { useTheme } from "../context/themeContext"
import topology from "../shared/world-topo.json"
import { COUNTRIES_VISITED, MAP_COLORS } from "../constants/constants"

const world = topojson.feature(topology, topology.objects.countries)

const Map = ({ width, height }) => {
  const theme = useTheme()
  const mode = theme.dark ? "dark" : "light"
  const mapColors = MAP_COLORS[mode]

  const {
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
  } = useTooltip()

  const centerX = width / 2
  const centerY = height / 2
  const scale = (width / 520) * 100

  const naturalEarthSvg = useMemo(() => {
    return (
      <svg width={width} height={height}>
        <NaturalEarth
          data={world.features}
          scale={scale}
          translate={[centerX, centerY]}
          rotate={[-10, 0, 0]}
        >
          {NaturalEarth => (
            <g>
              {NaturalEarth.features.map((f, i) => {
                const { path, feature } = f

                const hasVisited = COUNTRIES_VISITED.includes(
                  feature.properties.NAME
                )

                return (
                  <path
                    key={`map-feature-${i}`}
                    d={path || ""}
                    fill={
                      hasVisited
                        ? mapColors.LAND.VISITED.normal
                        : mapColors.LAND.UNVISITED
                    }
                    css={{
                      "&:hover": {
                        fill: hasVisited
                          ? mapColors.LAND.VISITED.hover
                          : mapColors.LAND.UNVISITED,
                      },
                    }}
                    stroke={
                      hasVisited ? mapColors.BOUNDARY : mapColors.LAND.UNVISITED
                    }
                    strokeWidth={0.3}
                    onMouseMove={event => {
                      if (hasVisited) {
                        const { x, y } = localPoint(event) || { x: 0, y: 0 }

                        showTooltip({
                          tooltipData: feature.properties.NAME,
                          tooltipLeft: x,
                          tooltipTop: y,
                        })
                      }
                    }}
                    onMouseLeave={() => {
                      hideTooltip()
                    }}
                  />
                )
              })}
            </g>
          )}
        </NaturalEarth>
      </svg>
    )
  }, [width, height, mode])

  return width < 10 ? null : (
    <>
      {naturalEarthSvg}
      {tooltipData && (
        <div style={{ position: "relative" }}>
          <Tooltip
            left={tooltipLeft - 10}
            top={tooltipTop - height - 50}
            style={{
              ...defaultStyles,
              background: mode === "dark" ? "#0a0a0a" : "#f6f6f6",
              color: mode === "dark" ? "#eeeeee" : "#000000",
              borderRadius: 6,
              minWidth: 72,
              padding: "8px 12px",
              textAlign: "center",
              transform: "translateX(-50%)",
            }}
          >
            {tooltipData}
          </Tooltip>
        </div>
      )}
    </>
  )
}

export default Map
